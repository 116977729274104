<template>
  <div>
    <component v-if="displayAddDialog" :is="addItemComponent" :succesFunction="succesFunction"
      @close="displayAddDialog = false" />
    <component v-if="displayUpdateDialog" :is="updateItemComponent" @close="closeUpdate" :item="itemToUpdate" />
    <delete-item-dialog v-if="displayDeleteDialog" :message="deleteMessageFunction(itemToUpdate)"
      :store-action="deleteAction" :item-payload="deletePayload" @close="closeDelete" />
    <h1 class="mb-6" v-if="headerText">{{ headerText }}</h1>
    <v-row justify="space-between">
      <v-col sm="4" md="4">
        <v-text-field type="search" label="Zoeken" prepend-inner-icon="mdi-magnify" clearable filled outlined
          single-line dense hide-details v-model="searchValue" />
      </v-col>
      <v-spacer v-if="!isMobileSize" />
      <v-col sm="4" class="d-flex justify-end">
        <v-btn outlined @click="enableFilters()" v-if="!!filters" class="tertiary text-none"
          :class="showCreateButton ? 'mr-2' : 'mr-0'">
          {{ isMobileSizeFilterText }}
          <v-icon v-bind="isMobileSizeFilterButtonBind">
            mdi-filter-variant
          </v-icon>
        </v-btn>
        <template v-if="showCreateButton && (addItemComponent !== null || addItemRoute !== null)">
          <v-btn v-if="!isMobileSize" class="secondary text-none" @click="onAddItemClick">
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ addBtnText }}
          </v-btn>
          <v-btn v-else class="secondary text-none" @click="onAddItemClick">
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
      </v-col>
    </v-row>
    <v-row v-if="isMobileSize">
      <v-col v-if="showFilters" cols="12">
        <overview-filter @update-filteritem="handleFilterCheckboxChange" @clearfilter="clearFilters" :filters="filters"
          :filterState.sync="showFilters" />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" :md="showFilters && !isMobileSize ? 10 : 12" class="pb-0">
        <data-table :headers="getHeaders()" :items="getFilteredItems" :search-value="searchValue"
          :no-data-text="noDataText" :no-results-text="noResultsText" @editItem="editItem" @deleteItem="deleteItem"
          :detail-page-function="detailPageFunction"
          :toggle-active-without-confirmation="toggleActiveWithoutConfirmation"
          :toggle-active-action="toggleActiveAction" :toggle-active-payload-function="toggleActivePayloadFunction"
          :activate-title="activateTitle" :activate-description="activateDescription"
          :deactivate-title="deactivateTitle" :deactivate-description="deactivateDescription"
          :delete-option="showDelete" :edit-option="() => !!onEdit" :disable-pagination="disablePagination"
          :localStorageSortingPrefix="localStoragePrefix">
          <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
            <slot :name="slot" v-bind="props" />
          </template>
        </data-table>
        <p class="text-body-2 pt-1 ml-1 grey--text">
          Totaal: {{ !getFilteredItems ? 0 : getFilteredItems.length }}/{{ !items ? 0 : items.length }}
        </p>
      </v-col>
      <template v-if="!isMobileSize">
        <v-col v-if="showFilters" sm="2" md="2">
          <overview-filter @update-filteritem="handleFilterCheckboxChange" @clearfilter="clearFilters"
            :filters="filters" :filterState.sync="showFilters" />
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import DataTable from "@/components/shared/DataTable";
import DeleteItemDialog from "@/components/shared/DeleteItemDialog";
import OverviewFilter from "./OverviewFilter.vue";

export default {
  name: "Overview.vue",
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    headers: {
      type: Array,
      required: true,
      default: () => []
    },
    noDataText: {
      type: String
    },
    noResultsText: {
      type: String
    },
    headerText: {
      type: String,
    },
    addBtnText: {
      type: String,
      default: "Nieuw",
      required: false
    },
    detailPageFunction: {
      type: Function
    },
    filters: {
      type: Array,
      default: () => []
    },
    addItemComponent: {
      required: false,
      default: null,
    },
    updateItemComponent: {
    },
    deleteMessageFunction: {
      type: Function,
    },
    deleteAction: {
      type: String,
    },
    deletePayloadFunction: {
      type: Function,
    },
    toggleActiveWithoutConfirmation: {
      type: Function,
      default: () => true
    },
    toggleActiveAction: {
      type: String
    },
    toggleActivePayloadFunction: {
      type: Function,
    },
    activateTitle: {
      type: String,
      default: "Activeren"
    },
    activateDescription: {
      type: String,
      default: "Bij het activeren van dit item wordt ook een bijbehorend item geactiveerd. Bent u zeker?"
    },
    deactivateTitle: {
      type: String,
      default: "Deactiveren"
    },
    deactivateDescription: {
      type: String,
      default: "Bij het deactiveren van dit item worden ook alle bijbehorende items gedeactiveerd. Bent u zeker?"
    },
    showActions: {
      default: true,
    },
    succesFunction: {
      type: Function,
      required: false
    },
    onDelete: {
      type: Function,
      default: null,
      required: false
    },
    onEdit: {
      type: Function,
      default: null,
      required: false
    },
    editRouterLink: {
      type: Object,
      default: null,
      required: false
    },
    showDelete: {
      type: Function,
      default: () => false,
    },
    addItemRoute: {
      type: Object,
      default: null,
      required: false
    },
    showCreateButton: {
      type: Boolean,
      required: false,
      default: true
    },
    disablePagination: {
      type: Boolean,
      default: true
    },
    localStoragePrefix: {
      type: String,
      requiered: true
    }
  },
  components: {
    DeleteItemDialog,
    DataTable,
    OverviewFilter,
  },
  data() {
    return {
      searchValue: null,
      showFilters: false,
      displayAddDialog: false,
      displayUpdateDialog: false,
      displayDeleteDialog: false,
      itemToUpdate: null,
      deletePayload: null,
      filteredItems: [],
      selectedFilterOptions: [],
    }
  },
  methods: {
    handleFilterCheckboxChange(option) {
      // First update it locally in view
      const existingOptionIndex = this.selectedFilterOptions.findIndex(opt => opt.label === option.label);
      if (existingOptionIndex !== -1) {
        // Replace the existing option
        this.selectedFilterOptions.splice(existingOptionIndex, 1, option);
      } else {
        // Add a new option
        this.selectedFilterOptions.push(option);
      }

      // Then update in localStorage for use in the future
      localStorage.setItem(this.localStorageFilterPrefix + option.id, JSON.stringify(option.active));
    },
    enableFilters() {
      this.showFilters = !this.showFilters;
    },
    clearFilters() {
      this.selectedFilterOptions.forEach(option => {
        option.active = false;
      });
      this.filters.forEach(filter => {
        filter.options.forEach(option => {
          localStorage.removeItem(this.localStorageFilterPrefix + option.id);
        });
      });
    },
    getHeaders() {
      if (this.showActions) {
        return this.headers.concat([{ value: 'actions', sortable: false, align: 'end' }])
      } else {
        return this.headers
      }
    },
    editItem(item) {
      if (this.onEdit) {
        this.onEdit(item)
      }
    },
    deleteItem(item) {
      this.itemToUpdate = item;
      this.deletePayload = this.deletePayloadFunction ? this.deletePayloadFunction(item) : item.id;
      this.displayDeleteDialog = true;
    },
    closeDelete() {
      this.displayDeleteDialog = false
      if (this.onDelete) {
        this.onDelete()
      }
    },
    closeUpdate() {
      this.displayUpdateDialog = false
    },
    autofillFilters() {
      // Get active filters from localStorage
      this.filters?.forEach(filter => {
        filter.options.forEach(option => {
          const filterOptionState = JSON.parse(localStorage.getItem(this.localStorageFilterPrefix + option.id));
          if (filterOptionState) {
            option.active = filterOptionState;
            this.selectedFilterOptions.push(option);
          }
        });
      })
    },
    autoShowFilters() {
      // Show filters sidebar if there were any active
      if (this.selectedFilterOptions.length > 0) {
        this.showFilters = true;
      }
    },
    onAddItemClick() {
      if (this.addItemComponent !== null) {
        this.displayAddDialog = true
      } else if (this.addItemRoute !== null) {
        this.$router.push(this.addItemRoute)
      }
    }
  },
  computed: {
    getFilteredItems() {
      const predicates = this.selectedFilterOptions.filter(option => option.active === true).map(option => option.predicate);
      return this.items.filter(item => predicates.every(predicate => predicate(item)));
    },
    // This is purely to change the text in the filter button.
    isMobileSizeFilterText() {
      if (this.isMobileSize) {
        return "";
      } else {
        return "Filter";
      }
    },
    // Provides a true or false so the layout changes dynamically. The filter, add button and data table use this for example. 
    isMobileSize() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    // Changes icon alignment when switching screen sizes.
    isMobileSizeFilterButtonBind() {
      if (this.isMobileSize) {
        return {
          right: false
        }
      } else {
        return {
          right: true
        }
      }
    },
    localStorageFilterPrefix() {
      return "overview-filter#" + this.localStoragePrefix + "#"
    }
  },
  watch: {
    filters: {
      handler() {
        this.autofillFilters()
        this.autoShowFilters()
      },
      immediate: true,
    }
  },
}
</script>